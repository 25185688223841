import { toRefs } from '@vueuse/core';
import { defineAsyncComponent, defineComponent, provide, reactive, watch, computed } from 'vue';
import TrajectoryMap from '@/views/trajectory/TrajectoryMap.vue';
import api from '@/api/trajectory';
import { useMessage } from '@/hooks/web/useMessage';
import { useRoute } from 'vue-router';
import sleep from '@/utils/sleep';
import { isNullOrUnDef } from '@/utils/is';
export default defineComponent({
  name: 'Trajectory',
  components: {
    TrajectoryMap,
    PosTable: defineAsyncComponent(() => import('@/views/trajectory/TrajectoryTable.vue')),
    FenceTable: defineAsyncComponent(() => import('@/views/trajectory/TrajectoryFence.vue'))
  },
  setup() {
    const route = useRoute();
    const messageBox = useMessage();
    const refRoot = reactive({
      cardInfo: null,
      posList: [],
      currentPosId: null
    });
    const refData = reactive({
      refMap: null,
      fetchLoading: false,
      showFence: false
    });
    const imei = computed(() => route.query.imei);
    async function onSearch({
      searchTime,
      objectid,
      posType,
      mdtid
    }) {
      if (searchTime.length === 0) {
        messageBox.warning('请选择时间');
        return;
      }
      if (!(objectid > 0) && mdtid == "") {
        messageBox.warning('请选择员工');
        return;
      }
      refRoot.cardInfo = null;
      refRoot.posList = [];
      refRoot.currentPosId = null;
      refData.fetchLoading = true;
      await sleep(500);
      try {
        if (posType === -1) {
          // @ts-ignore
          posType = undefined;
        }
        const startTime = searchTime[0].valueOf();
        const endTime = searchTime[1].valueOf();
        const result = await api.getTrajectory({
          startTime,
          endTime,
          objectid: objectid,
          mdtid: mdtid,
          posType
        });
        if (result.code === 0) {
          refRoot.cardInfo = result.data.objectinfo;
          refRoot.posList = result.data.basePosDtoList.map((pos, posId) => ({
            posId,
            address: '',
            ...pos
          }));
          if (refRoot.posList.length > 0) {
            refRoot.currentPosId = refRoot.posList[0].posId;
          }
          messageBox.success(result.msg);
          return;
        }
        messageBox.warning(result.msg);
      } catch (err) {
        console.error(err);
      } finally {
        refData.fetchLoading = false;
      }
    }
    // 点击围栏
    function fenceClick(row) {
      if (isNullOrUnDef(row)) {
        // 清空地图上的围栏
        refData.refMap.renderPolygon();
        return;
      }
      if (row.type === 1) {
        refData.refMap.renderPolygon(row.pointList);
      } else if (row.type === 2) {
        refData.refMap.renderPolygon(row.pointList);
      }
    }
    function initProvide() {
      provide('RootProvide', refRoot);
    }
    watch(() => refData.showFence, showFence => {
      if (!showFence) {
        fenceClick();
      }
    });
    initProvide();
    return {
      ...toRefs(refData),
      onSearch,
      fenceClick
    };
  }
});